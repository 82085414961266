import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import MySwiper from '../components/Swiper'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import '../fonts/fontawesome-all.min.css'
import '../fonts/fontawesome.min.css'
// images
import svc1 from '../assets/images/svc1.svg'
import svc2 from '../assets/images/svc2.svg'
import heartLogo from '../assets/images/heart-logo.png'

class IndexPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Perfectly Matched" description={siteDescription} />

        <div className="slider">
          <div className="brandstamp text-white">
            <div className="text-center">
              <div>
                <LazyLoadImage src={heartLogo} alt="Perfectly Matched Dating" />
                <h1 className="mt-4 mb-0">
                  <strong>Matchmaking based on compatibility&nbsp;</strong>
                  <br />
                </h1>
                <h2 className="mt-0 mb-5">
                  30 YEARS OF MATCHMAKING EXPERIENCE
                  <br />
                </h2>
                <Link to="/profile-test" className="btn-global main-cta">
                  TAKE PROFILE TEST
                </Link>
              </div>
            </div>
          </div>
          <div className="slide1" />
          <div className="slide2" />
          <div className="slide3" />
          <div className="slide4" />
        </div>

        <div className="about-section">
          <div className="container">
            <div className="row flex-column-reverse flex-md-row">
              <div className="col-md-5 text-white">
                <div className="about-content">
                  <h2 className="mb-0">ABOUT PERFECTLY MATCHED</h2>
                  <hr />
                  <p className="mb-4 text-justify">
                    At Perfectly Matched, the longest established dating service
                    in Colorado Springs, what sets my matchmaking apart is my
                    personalized service. As a professional matchmaker with over
                    30 years of success, 29 years with Perfectly Matched, I
                    provide valuable experience and insight to the process of
                    meeting, dating and relationships.
                    <br />
                  </p>
                  <div>
                    <Link className="btn-global btn-white" to="/about">
                      Learn More
                    </Link>
                    <a
                      className="social-btn btn-white"
                      href="https://www.facebook.com/perfectlymatched"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-facebook" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="about-img-container">
                  <div className="about-overlay" />
                  <div className="about-vid">
                    <iframe
                      width="420"
                      height="315"
                      src="https://www.youtube.com/embed/mqJCYWBbaBA"
                      frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                      title="About Perfectly Matched"
                    />
                  </div>
                  {/*<video className="about-vid" width="100%" controls>
                    <source
                      src="https://res.cloudinary.com/dhexit7nv/video/upload/v1575601295/Perfectly_Matched_uchnp5.mp4#t=0.8"
                      type="video/mp4"
                    />
                    <source
                      src="https://res.cloudinary.com/dhexit7nv/video/upload/v1575601295/Perfectly_Matched_uchnp5.mp4#t=0.8"
                      type="video/ogg"
                    />
                    <track srclang="eng" label="English" kind="captions" />
                    Your browser does not support the video tag.
                  </video>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="svc-section">
          <div className="container">
            <div>
              <h2 className="mt-0 text-center">What I Do</h2>
              <hr />
              <p className="mb-3 subheading text-center">
                As a professional matchmaker with over 30 years of success, 29
                years with Perfectly Matched, I provide valuable experience and
                insight to the process of meeting, dating and relationships.
                <br />
              </p>
              <div className="row">
                <div className="col-md-6">
                  <div>
                    <div className="text-center mt-3 svc-heading">
                      <LazyLoadImage
                        className="img-fluid"
                        src={svc1}
                        alt="Matchmaking"
                      />
                      <h4 className="mt-2">Matchmaking</h4>
                    </div>
                    <p className="svc-para text-center">
                      People come to me knowing they are going to meet other
                      singles who are serious about finding that perfect match.
                      My clients appreciate the fact that they receive
                      personalized, one-on-one coaching and counseling along the
                      way to their perfect match.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div>
                    <div className="text-center mt-3 svc-heading">
                      <LazyLoadImage
                        className="img-fluid"
                        src={svc2}
                        alt="Coaching and Counseling"
                      />
                      <h4 className="mt-2">Coaching &amp; Counseling</h4>
                    </div>
                    <p className="svc-para text-center">
                      I take matchmaking seriously as well, so there's no game
                      playing on either side. Many of my clients have tried
                      using dating apps and sites, but found them sadly lacking
                      in the personal, human element so vital to successful
                      matchmaking.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="reviews-section">
          <div className="container">
            <h2 className="text-white text-center">Testimonials</h2>
            <hr className="mb-4" />
            <MySwiper slidesperview={3}>
              <div>
                {' '}
                <div className="testi-content">
                  <p className="mb-0">
                    <i className="fas fa-quote-left" /> The only thing missing
                    in my life was having someone special. I have a great job,
                    great home, great friends but I did not have time to try to
                    meet someone on my own. My mother bought me a membership to
                    Perfectly Matched for Christmas last year and in May I met
                    Mark.....so far so good.
                    <i className="fas fa-quote-right" />
                    <br />
                  </p>
                  <div className="testi-triangle" />
                  <h5 className="mt-3 mb-0">- Heather</h5>
                </div>
              </div>
              <div>
                <div className="testi-content">
                  <p className="mb-0">
                    <i className="fas fa-quote-left" /> Donna is amazingly
                    insightful and her match test is spot on. She had great,
                    high quality matches for me and I would recommend her
                    services to anyone who is single.{' '}
                    <i className="fas fa-quote-right" />
                    <br />
                  </p>
                  <div className="testi-triangle" />
                  <h5 className="mt-3 mb-0">- Kathy</h5>
                </div>
              </div>
              <div>
                <div className="testi-content">
                  <p className="mb-0">
                    <i className="fas fa-quote-left" /> You introduced me to
                    Danny in 1992. Our sixteenth wedding anniversary is coming
                    up. We have 3 beautiful children, Jeni will be 14, Sara is
                    12 and Levi is 10. We’ve been through a lot...but don’t
                    think we could have made it without each other. Thanks for
                    putting us together Donna!
                    <i className="fas fa-quote-right" />
                    <br />
                  </p>
                  <div className="testi-triangle" />
                  <h5 className="mt-3 mb-0">- Shelley</h5>
                </div>
              </div>
              <div>
                <div className="testi-content">
                  <p className="mb-0">
                    <i className="fas fa-quote-left" /> Many times I would meet
                    someone and walk away not knowing whether they were or were
                    not interested. With Donna as a third party and her input
                    and involvement before and after each of the initial
                    meetings, you always know where you stand.{' '}
                    <i className="fas fa-quote-right" />
                    <br />
                  </p>
                  <div className="testi-triangle" />
                  <h5 className="mt-3 mb-0">- Susan</h5>
                </div>
              </div>
              <div>
                {' '}
                <div className="testi-content">
                  <p className="mb-0">
                    <i className="fas fa-quote-left" /> What I appreciate about
                    Perfectly Matched is Donna's personalized service. I trust
                    her judgment and respect her talents as a matchmaker. The
                    feedback after each meeting has been very insightful and
                    informative. She made a real effort to get to know me and I
                    have met some great quality men.
                    <i className="fas fa-quote-right" />
                    <br />
                  </p>
                  <div className="testi-triangle" />
                  <h5 className="mt-3 mb-0">- Linda</h5>
                </div>
              </div>
              <div>
                <div className="testi-content">
                  <p className="mb-0">
                    <i className="fas fa-quote-left" /> I really believe in
                    Donna’s Compatibility Test. I can’t believe how much I have
                    in common with Terry. We think so much alike and get along
                    so well it&#39;s almost scary.{' '}
                    <i className="fas fa-quote-right" />
                    <br />
                  </p>
                  <div className="testi-triangle" />
                  <h5 className="mt-3 mb-0">- Dave</h5>
                </div>
              </div>
            </MySwiper>
            <div className="text-center mt-5">
              <Link className="btn-global" to="/testimonials">
                View More
              </Link>
            </div>
          </div>
        </div>
        <div className="vprops-section">
          <div className="container">
            <h2 className="mb-0 mt-0 text-center">
              <strong>Compatibility Testing</strong>
              <br />
            </h2>
            <hr />
            <p className="subheading mb-5 text-center">
              A great deal of the success I enjoy over other dating services and
              matchmakers is based on the fact that I start with the things that
              really matter. The foundation of any happy, long lasting
              relationship is based on compatibility and the profile test I work
              with pin points my clients on a scale of "0" to "10" in six key
              areas of life:
              <br />
            </p>
            <div className="container">
              <div className="row">
                <div className="col-md-4 p-0">
                  <div className="vp-1 text-center">
                    <div className="vp-overlay">
                      <div className="vp-heading">
                        <i className="far fa-smile-beam" />
                        <h3 className="vp-heading mt-0 text-white">
                          Temperament
                          <br />
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 p-0">
                  <div className="vp-2 text-center">
                    <div className="vp-overlay">
                      <div className="vp-heading">
                        <i className="far fa-comments" />
                        <h3 className="vp-heading mt-0 text-white">
                          Sociability
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 p-0">
                  <div className="vp-3 text-center">
                    <div className="vp-overlay">
                      <div className="vp-heading">
                        <i className="fas fa-users" />
                        <h3 className="vp-heading mt-0 text-white">
                          Conformity
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 p-0">
                  <div className="vp-4 text-center">
                    <div className="vp-overlay">
                      <div className="vp-heading">
                        <i className="fas fa-hand-holding-heart" />
                        <h3 className="vp-heading mt-0 text-white">
                          Affection
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 p-0">
                  <div className="vp-5 text-center">
                    <div className="vp-overlay">
                      <div className="vp-heading">
                        <i className="fas fa-praying-hands" />
                        <h3 className="vp-heading mt-0 text-white">Religion</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 p-0">
                  <div className="vp-6 text-center">
                    <div className="vp-overlay">
                      <div className="vp-heading">
                        <i className="fas fa-piggy-bank" />
                        <h3 className="vp-heading mt-0 text-white">Finance</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p className="pt-5 mb-0 subheading text-center">
              Once I receive your personality profile test scores, I will call
              you to go over the results. If you want to proceed with my dating
              services from there, you meet with me for a hour interview and
              based on the compatible singles already in my system and your
              preferences, we will discuss your options and the next steps to
              take on your journey to finding your perfect match.
              <br />
            </p>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
