import React from 'react'
import Swiper from 'react-id-swiper'
import { Pagination, Navigation } from 'swiper/dist/js/swiper.esm'
import 'swiper/dist/css/swiper.css'

class SwiperSample extends React.Component {
  render() {
    const { children, slidesperview } = this.props
    const params = {
      modules: [Pagination, Navigation],
      slidesPerView: slidesperview,
      spaceBetween: 30,
      containerClass: 'swiper-container pb-5',
      nested: true,
      loop: true,
      autoplay: {
        delay: 10000,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'line',
        clickable: true,
      },
      navigation: {
        prevEl: '.carousel-control-next-icon',
        nextEl: '.carousel-control-prev-icon',
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
      },
    }
    return <Swiper {...params}>{children}</Swiper>
  }
}

export default SwiperSample
